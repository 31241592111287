<template>
  <div>
    <Toolbar>
      <template v-slot:title>
        {{ $t("pages.userManagement.title") }}
      </template>
      <template v-slot:subNavigation>
        <ToolbarNavigation />
      </template>
    </Toolbar>

    <section class="min-h-screen-toolbar py-6 md:py-10">
      <router-view></router-view>
    </section>
  </div>
</template>

<script>
import ToolbarNavigation from "@/components/user-management/ToolbarNavigation";

export default {
  name: "UserManagement",
  components: {
    ToolbarNavigation,
  },
};
</script>
